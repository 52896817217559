import React from 'react'
import { graphql } from 'gatsby'
import { Layout, LocaleConfig, PageTitle, SEO } from 'modules/core'
import { FAQList } from '../modules/faq'

export interface FAQData {
    allMdx: {
        edges: Array<{
            node: {
                frontmatter: {
                    title: string
                    description: string
                }
                body: string
            }
        }>
    }
}

export const query = graphql`
    query FAQQuery($locale: String) {
        allMdx(
            filter: {
                fileAbsolutePath: { glob: "**/content/faq/*.mdx" }
                frontmatter: { locale: { eq: $locale } }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        description
                    }
                    body
                }
            }
        }
    }
`

interface FAQPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: FAQData
}

const FAQPage = ({ data, pageContext, path }: FAQPageProps) => {
    const faq = {
        title: data.allMdx.edges[0].node.frontmatter.title,
        description: data.allMdx.edges[0].node.frontmatter.description,
        body: data.allMdx.edges[0].node.body,
    }

    return (
        <Layout locale={pageContext.localeConfig} path={path}>
            <SEO title={faq.title} description={faq.description} />
            <PageTitle>{faq.title}</PageTitle>
            <FAQList>{faq.body}</FAQList>
        </Layout>
    )
}

export default FAQPage
