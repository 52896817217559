import React, { useState, cloneElement, PropsWithChildren } from 'react'
import styled from 'styled-components'

export const FAQ = ({ children }: PropsWithChildren<any>) => {
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = () => setIsOpen((prev) => !prev)
    const [question, answer] = React.Children.toArray(children)

    if (!React.isValidElement(question) || !React.isValidElement(answer)) {
        return null
    }

    return (
        <FAQContainer>
            {cloneElement(question, { onClick: toggleOpen, isOpen })}
            {isOpen && answer}
        </FAQContainer>
    )
}

const FAQContainer = styled.div`
    width: 100%;
    margin-bottom: 48px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightBackground};
`
