import React from 'react'
import styled, { css } from 'styled-components'
import { Link, withPrefix } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { Container } from '../core'
import { FAQ } from './FAQ'

interface FAQProps {
    // MDX string
    children: string
}

export const FAQList = ({ children }: FAQProps) => {
    return (
        <MDXProvider components={{ Link, FAQ, Question, Answer }}>
            <Container>
                <MDXRenderer>{children}</MDXRenderer>
            </Container>
        </MDXProvider>
    )
}

const Node = (content: string, color: string, backgroundColor: string) => {
    const size = '45px'
    const mobileSize = '24px'

    return css`
        position: relative;
        font-size: 20px;
        line-height: 30px;
        cursor: pointer;
        margin-bottom: 48px;
        padding: 8px 66px 0 95px;
        ${({ theme }) => theme.typography_presets.bold};

        @media ${({ theme }) => theme.breakpoints.phone_only} {
            font-size: 14px;
            line-height: 21px;
            padding: 1px 22px 0 32px;
            margin-bottom: 32px;
        }

        &:before {
            content: '${content}';
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: ${size};
            height: ${size};
            font-size: 28px;
            font-family: ${({ theme }) => theme.typography.boldFontFamily};
            border-radius: 100%;
            color: ${color};
            background-color: ${backgroundColor};

            @media ${({ theme }) => theme.breakpoints.phone_only} {
                width: ${mobileSize};
                height: ${mobileSize};
                font-size: 14px;
            }
        }
    `
}

const Caret = (isOpen: boolean) => css`
    &:after {
        position: absolute;
        top: 7px;
        right: 0;
        content: '';
        width: 18px;
        height: 10px;
        background-image: url('${withPrefix(
            `/images/icons/${isOpen ? 'caret-up.svg' : 'caret-down.svg'}`
        )}');
        background-repeat: no-repeat;
        background-position: center;
    }
`

const Question = styled.div<{ isOpen: boolean }>`
    ${({ theme }) => Node('?', theme.colors.text, theme.colors.accent)};
    ${({ isOpen }) => Caret(isOpen)};
`

const Answer = styled.div`
    ${({ theme }) =>
        Node('A', theme.colors.accent, theme.colors.invertedBackground)};
    font-size: 14px;
    line-height: 26px;
    font-family: ${({ theme }) => theme.typography.defaultFontFamily};
    font-weight: ${({ theme }) => theme.typography.weights.medium};
`
